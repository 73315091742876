<template>
  <div v-if="this.current !== null && this.category !== null">
    <Header />

    <section class="guide-dem-header">
      <div class="h2">
        <router-link :to="{ name: 'guide-dem-list' }" rel="canonical">
          Location-gardemeuble.fr : Guide Déménagement
        </router-link>
      </div>

      <div class="guide-center">
        <div class="h1">
          {{ this.category.name }}
        </div>
        <p>
          {{ this.category.text }}
        </p>
        <div>
          {{ this.category.sub }}
        </div>
      </div>

      <nav v-if="this.store.getters.isDesktop">
        <router-link v-for="cat in this.categories" :to="{ name: 'guide-dem-category', params: { slug: cat.slug, category: cat.id } }" :class="(cat.id === this.category.id) ? 'current' : ''">
          {{ cat.name }}
        </router-link>
      </nav>

      <div class="cat-selector" v-if="this.store.getters.isMobile">
        <label for="cat">Sélectionnez une catégorie :</label>
        <select id="cat" @change="this.onCategoryChange($event)">
          <option value="guide-demenagement-sommaire">
            Sommaire du guide
          </option>
          <option v-for="cat in this.categories" :value="cat.id" :selected="cat.id === this.category.id">
            {{ cat.name }}
          </option>
        </select>
        <br><br>
      </div>
    </section>

    <div class="articles-container-list">
      <article class="solo-article">
        <h1 class="title">
          {{ this.current.title }}
        </h1>
        <p class="text" v-html="this.current.text?.replaceAll( 'https://www.location-gardemeuble.fr/img/upload/', 'https://api.location-gardemeuble.fr/img/upload/' )"></p>
      </article>

      <aside v-if="this.store.getters.isDesktop">
        <div class="embedded-search">
          <h3 v-html="this.category.search_text ?? 'Trouvez un garde meuble au meilleur prix'"></h3>
          <SearchBar />
        </div>
        <hr>
        <div v-html="this.category.right_text?.replaceAll( 'https://www.location-gardemeuble.fr/img/upload/', 'https://api.location-gardemeuble.fr/img/upload/' )"></div>
      </aside>
    </div>

    <div class="articles-container">
      <section class="articles">
        <div class="row list">
          <article v-for="article in this.category?.articles?.filter( art => art.id !== this.current.id ).splice( 0, 4 )">
            <a :href="article.url">
              <p class="title">
                {{ article.title }}
              </p>
            </a>
            <a :href="article.url" class="img">
              <img :src="this.store.state.api + '/img/upload/dem_articles/' + article.id + '.jpg'" :alt="article.title" width="250" height="150" />
            </a>
          </article>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import { useMeta } from "vue-meta";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

library.add( faEye );

@Options( {
  components : {
    FontAwesomeIcon,
    Header    : defineAsyncComponent( () => import("@/components/Header.vue") ),
    Footer    : defineAsyncComponent( () => import("@/components/Footer.vue") ),
    SearchBar : defineAsyncComponent( () => import("@/components/SearchBar.vue") )
  }
} )
export default class extends Vue {
  store = useStore( key );

  categories = [];
  current    = {};
  category   = {};

  meta = setup( () => useMeta( computed( () => {
    return {
      title       : [{
        content : "Location-gardemeuble.fr - Guide du déménagement - " + (this.current?.title ?? "Article" ),
        vmid    : "title",
        name    : "title"
      }],
      description : {
        content : (this.category?.sub ?? "Le guide du déménagement de Location-gardemeuble.fr, tout savoir sur comment bien déménager sans s'embêter !"),
        vmid    : "description",
        name    : "description"
      }
    };
  } ) ) );

  onCategoryChange( event )
  {
    // Find cateogory by id from this.categories
    let category = this.categories.find( cat => cat.id === parseInt( event.target.value ) );

    if( event.target.value === "guide-demenagement-sommaire" )
    {
      // Redirect to guide
      this.$router.push( { name: "guide-dem-list" } );
      return;
    }

    // Redirect to category
    this.$router.push( { name : "guide-dem-category", params : { slug : category.slug, category : category.id } } );
  }

  async getCategories()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/categories", {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json        = await request.json();
    this.categories = json.data;
  }

  async getArticle()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/articles/" + this.$route.params.id, {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json     = await request.json();
    this.current = json.data;
  }

  async getCategory()
  {
    let request = await fetch( this.store.state.api + "guide/demenagement/categories/" + this.current.category.id, {
      method  : "GET",
      cache   : "no-cache",
      headers : {
        "Content-Type" : "application/json",
        "Accept"       : "application/json"
      }
    } );

    let json      = await request.json();
    this.category = json.data;
  }

  async mounted()
  {
    try
    {
      await this.getCategories();
      await this.getArticle();
      await this.getCategory();
    }
    catch( e )
    {
      console.error( e );
      // TODO : Better error handling
    }

    setTimeout( () => {
      this.store.state.loading = false;
      this.store.state.current = this.current;

      // Remove div#preload from DOM
      let preload = document.getElementById( "preload" );
      if( preload )
      {
        window.scrollTo( { top : 0 } );
        preload.remove();
      }
    }, this.store.state.delay );  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

.solo-article
{
  max-width : 70%;
  margin    : 2em;
  font-size : 0.8em;

  @media (max-width : 768px)
  {
    max-width : 95%;
    margin    : 2em 1em;
  }

  h1, h2
  {
    font-size  : 1.75em;
    text-align : center;
    margin     : 0 auto 1em;
  }

  .text
  {
    *
    {
      font-family : "Roboto", sans-serif !important;
      color       : $fontColor !important;
      background  : transparent !important;
      line-height : 1.5em !important;
      text-align  : justify;
    }

    h1, h2
    {
      font-size   : 1.5em;
      text-align  : left;
      margin      : 0 auto 1em;
      font-weight : bold;
    }

    a
    {
      color : $lightBlue !important;

      &:hover
      {
        text-decoration : underline;
      }
    }
  }
}

.row
{
  // under 768px
  @media (max-width : 768px)
  {
    flex-direction : column;
  }
}
</style>
